import React from "react";
import { BaseLayout } from "../components/Layout";

const theCareAct = () => {
  return (
    <BaseLayout page="care" leftBox="about" rightBox="services">
      <section className="outerContainer max-w-screen-2xl text-black-950 flex flex-col gap-5 mx-auto">
        <p className="">
          Turn the Tides supports vulnerable adults to live independently in
          their communities. The work we do contributes towards meeting social
          care needs.
        </p>

        <ul className=" flex flex-col gap-5 p-5">
          <li>
            <h4 className="mb-2 font-bold">
              a) managing and maintaining nutrition.
            </h4>
            <p>
              When a person is housebound we can support them to compile a
              shopping list of provisions and can shop for them. If they are
              able to use the internet we can support them to set up online
              shopping.
            </p>
          </li>
          <li>
            <h4 className="mb-2 font-bold">
              (b) maintaining personal hygiene.{" "}
            </h4>
            <p>
              By ensuring bathroom areas are clean and hygienic we promote the
              use of washing facilities, enabling the person to access these and
              encouraging them to change used towels and dispose of old
              toiletries as required.
            </p>
          </li>
          <li>
            <h4 className="mb-2 font-bold">(c) managing toilet needs. </h4>
            <p>
              We do not provide personal care but keep toilets clean and
              flushing. This means that a person with continence issues has
              access to their working toilet and can make full use of the
              facility. We encourage the person to dispose of used pads in
              lidded bins with liners so these can be taken to kerbside bins
              easily. We clear and clean human waste and used pads.
            </p>
          </li>
          <li>
            <h4 className="mb-2 font-bold">(d) being appropriately clothed.</h4>
            <p>
              We support people to make use of the laundry facilities in their
              home. Most of the people we work with have washing machines and we
              can help them use these, if required. When a person is unsure
              regarding the best way to dry clothing we can advise them with
              regards to keeping rooms well-ventilated and maintaining clear
              access routes.
              <br />
              <br />
              Being enabled to use laundry facilities means that a person is
              more likely to have appropriate clothing choices.
            </p>
          </li>
          <li>
            <h4 className="mb-2 font-bold">
              (e) being able to make use of the adult’s home safely.
            </h4>
            <p>
              We support people to declutter their homes and clear access routes
              through the property to maintain safety. Clutter can be a fire
              risk and trip hazard: we work to reduce these risks.
              <br />
              <br />
              Sometimes people can leave flammable material, such as paperwork,
              near sources of heat, e.g. Cookers, candles. We support them to
              allocate more appropriate places for paperwork and encourage them
              to keep cooking areas free of extraneous matter.
              <br />
              <br />
              Where people are smokers we ensure ashtrays are emptied and kept
              free of flammable materials.
            </p>
          </li>
          <li>
            <h4 className="mb-2 font-bold">
              (f) maintaining a habitable home environment.
            </h4>
            <p>
              Some people have a large amount of clutter and may identify as
              hoarders. We take a non-judgmental and sensitive approach to
              hoarding, respecting a person’s choices and attempting to find a
              satisfactory way to manage the hoard without distressing the
              person who owns the items.
              <br />
              <br />
              A large amount of clutter is not necessarily a hoard. We work with
              people who may have physical health limitations or struggle with
              motivation to sort through belongings. Sometimes they just don’t
              know where to start. It is not that they are necessarily overly
              attached to their possessions, they just aren’t sure what is
              actually there. We help them sort through the items, encouraging
              them to make decisions about what to keep and what to discard.
              <br />
              <br />
              We empower people to direct the sorting and clearing process,
              enabling them to develop new skills and a sense of achievement.
            </p>
          </li>
          <li>
            <h4 className="mb-2 font-bold">
              (g) developing and maintaining family or other personal
              relationships.
            </h4>
            <p>
              When a person’s home gives them pride they feel more able to
              invite guests in. Many of the people we have worked with have said
              that they are now happy for family and friends to visit, and
              relationships have been rekindled.
            </p>
          </li>
          <li>
            <h4 className="mb-2 font-bold">
              (h) accessing and engaging in work, training, education, or
              volunteering.
            </h4>
            <p>
              Through supporting people to feel safe and secure at home, to have
              access to clean clothing and nutrition, we are laying a foundation
              whereby they can feel more confident in the workplace, including
              volunteering or undertaking courses in the community.
            </p>
          </li>
          <li>
            <h4 className="mb-2 font-bold">
              (i) making use of necessary facilities or services in the local
              community including public transport, and recreational facilities
              or services.
            </h4>
            <p>
              We work with a number of people who have no access to the internet
              and rarely leave their homes. We can assist them by searching for
              what’s happening in their neighbourhood and providing phone
              numbers for organisations/events they are interested in
              investigating further, including access to transport options.
            </p>
          </li>
        </ul>
      </section>
    </BaseLayout>
  );
};

export default theCareAct;
